import React from "react"
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import titreRubrique from "../assets/images/accueil/puce_titre_rubrique.gif"
import mainTitle from "../assets/images/titres/la_mesure_notre_metier.gif"
import historique from "../assets/images/titres/historique_EC3D.gif"
import references from "../assets/images/titres/references_EC3D.gif"
import aeroSpatial from "../assets/images/accueil/references_aero_spatial_auto_defense_EC3D.jpg"
import plus from "../assets/images/titres/les_plus_EC3D.gif"
import "./index.css"
import HomepageLayout from "../components/homepageLayout";

const IndexPage = ({data}) => (
  <HomepageLayout>
    <SEO title="Spécialiste de la Mesure Tridimensionnelle" />
    <div id="homepage">
      <div id="titre">
          <img src={titreRubrique} width="25" height="32" alt="puce titre rubrique"/>
          <span class="h1"><img src={mainTitle} width="518" height="32" alt="Rubrique Notre Savoir-Faire - EC3D" /></span>
          <p class="titre-texte h2" dangerouslySetInnerHTML={{__html: data.allContentfulAccueil.edges[0].node.mainText.mainText}}>
          </p>
      </div>
              
      <div id="contenu">
        <div class="block history-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={historique} width="131" height="22" alt="Historique EC3D" /></div>
            <div class="block-content h3">
              {data.allContentfulAccueil.edges[0].node.history.elements.map(history => (
                <div class="element">
                  <div class="element-title">{history.date}</div>
                  <div class="element-legend">{history.description.description}</div>
                </div>
              ))}
            </div>  
          </div>  
        </div>
        
        <div class="block references-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={references} width="147" height="22" alt="Références EC3D" /></div>
            <div class="block-content h3">
              <img src={aeroSpatial} alt="Références EC3D Aéronautique et Défense Spatial Automobile" width="264" height="36" vspace="11" />
              {data.allContentfulAccueil.edges[0].node.references.references.map(reference => (
                <div class="element">
                  <strong>{reference.titreRfrence} :</strong> {reference.descriptionRfrence.descriptionRfrence}
                </div>
              ))}
            </div>  
          </div>  
        </div>
        
        <div class="block plus-block">
          <div class="inner-block">
            <div class="block-title h1"><img src={plus} width="184" height="22" alt="Les plus d'EC3D" /></div>
            <div class="block-content h3">
              <strong>{data.allContentfulAccueil.edges[0].node.plus.sousTitre}</strong> 
              <ul>
              {data.allContentfulAccueil.edges[0].node.plus.plus.map(plusElement => (
                <li>
                  <span>{plusElement.text.text}</span>
                </li>
              ))}
              </ul>
            </div>  
          </div>  
        </div>
        
      </div>
    </div>
  </HomepageLayout>
)

export default IndexPage

export const query = graphql`
    query {
      allContentfulAccueil {
        edges {
          node {
            id,
            mainText{
              mainText
            },
            hero{
              file {
                url
              }
            },
            history{
              id,
              titre,
              elements{
                id,
                date,
                description{
                  description
                },
              }
            },
            references{
              id,
              titre,
              references{
                id,
                titreRfrence,
                descriptionRfrence{
                  descriptionRfrence
                },
              }
            },
            plus{
              id,
              titre,
              sousTitre,
              plus{
                id,
                text{
                  text
                },
              }
            }
          }
        }
      }
    }
  `